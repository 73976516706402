@import url('https://fonts.googleapis.com/css?family=Jost:100,100italic,200,200italic,300,300italic,400,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic');

body {
  --white: #ffffff;
  --off-white: #fafafa;
  --trufo-light: #d1d7e0;
  --trufo-dark: #23232a;

  margin: 0;
  overflow-x: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100%;
  max-width: 100%;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--off-white);
  color: var(--trufo-dark);
  font-family: 'Jost', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-width: 100%;
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus:not(:focus-visible) {
  outline: none
}

html {
  /* scrollbar-gutter: stable; */
  height: -webkit-fill-available;
}