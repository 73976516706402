@import url(../../../index.css);

.top-layer {
    background-color: var(--trufo-light);
    color: var(--trufo-dark);
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.top-layer-overlay {
    background-color: transparent;
}

.bottom-layer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.app-layer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}

.position-absolute {
    position: absolute;
}

.disable-scrollbar::-webkit-scrollbar {
    /* Chrome, Safari and Opera */
    display: none;
}

.disable-scrollbar {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;
}